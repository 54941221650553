import React from 'react';

import { MetricCard, MetricCardAlignment, MetricCardSize } from 'components/MetricCard';
import { Divider } from 'components/Divider';
import useMediaQuery from 'utils/useMediaQuery';
import { Direction } from 'enums/Direction';
import { Colors } from 'enums/Colors';
import { sendEmail, timeClockCircle, bulb } from 'icons/default';

import styles from './Footer.module.scss';
import { FooterNavList } from './FooterNavList';
import { FooterBlippInfo } from './FooterBlippInfo';
import { FooterSocial } from './FooterSocial';
import { FooterBlippAdminInfo } from './FooterBlippAdminInfo';
import { footerNavList } from './footer-data/footerData';

export const Footer: React.FC = () => {
  const isLargeScreen = useMediaQuery('(min-width: 1440px)');

  return (
    <footer className={styles.footer}>
      <div className={styles.inner}>
        <section className={styles.contactSection}>
          <MetricCard
            icon={sendEmail}
            title="Kontakta oss"
            value="hej@blipp.se"
            href="mailto:hej@blipp.se"
            size={isLargeScreen ? MetricCardSize.Large : MetricCardSize.Small}
            direction={Direction.Horizontal}
            alignment={MetricCardAlignment.Center}
          />

          <MetricCard
            icon={timeClockCircle}
            title="Öppettider"
            value={`Mån-fre: 9-18
            Lör-sön: 11-16 (endast bokningar)`}
            size={isLargeScreen ? MetricCardSize.Large : MetricCardSize.Small}
            direction={Direction.Horizontal}
            alignment={MetricCardAlignment.Center}
          />

          <MetricCard
            icon={bulb}
            title="Snabba svar"
            value="Vanliga frågor"
            href="https://support.blipp.se/"
            size={isLargeScreen ? MetricCardSize.Large : MetricCardSize.Small}
            direction={Direction.Horizontal}
            alignment={MetricCardAlignment.Center}
          />
        </section>
        <Divider thickness={1} borderColor={Colors.Redish700} />
        <div className={styles.sectionWrapper}>
          <section className={`${styles.blippInfoSection} ${styles.tablet}`}>
            <FooterBlippInfo />
          </section>
          <section className={styles.navSection}>
            <nav>
              {footerNavList.map((navlist) => (
                <FooterNavList
                  key={navlist.heading}
                  heading={navlist.heading}
                  list={navlist.list}
                />
              ))}
            </nav>
          </section>
        </div>
        <section className={styles.socialSection}>
          <FooterSocial />
        </section>
        <Divider thickness={1} />
        <section className={styles.blippInfoSection}>
          <FooterBlippInfo />
        </section>
        <section className={styles.blippAdminInfoSection}>
          <FooterBlippAdminInfo />
        </section>
      </div>
    </footer>
  );
};
